@import 'styles/variables';

.checkbox-wrapper {
  display: flex;
  margin-left: 290px;
}

.checkbox {
  display: flex;
  align-items: center;

  &--disabled {
    color: $text-grey;
  }

  label {
    margin-left: 4px;
  }

  svg {
    margin-top: 3px;
    margin-left: 8px;
  }
}
