@import 'styles/variables';

.trial-boundary-form {
  > div {
    margin-left: 20%;
  }

  @media #{$media-mobile--sm} {
    > div {
      margin-left: 0;
    }
  }
}
