@import 'styles/variables';
@import 'styles/mixins';

.auth-form-wrapper {
  width: 400px;
  max-width: 400px;
  margin: 40px auto;
  padding: 24px;
  border: 1px solid $bg-light--tertiary;
  border-radius: $border-radius--md;

  &--merged {
    &-top {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-bottom {
      margin-top: -41px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &--merged-top &__footer {
    display: none;
  }

  &__header,
  &__footer {
    text-align: center;
  }

  &__header {
    margin: 16px 0 32px 0;
    font-size: $font-size--xxl;
    font-weight: $font-weight--medium;
  }

  &__form form {
    @include auth-form();
  }

  &__footer {
    margin-top: 16px;

    > div:last-child {
      margin-top: 28px;
      justify-content: center;

      a {
        color: $brand-color--secondary;
        text-decoration: none;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  @media #{$media-mobile--sm} {
    width: 90%;
    padding: 16px;

    &__header {
      margin: 8px 0 24px 0;
      font-size: $font-size--xl;
      line-height: $font-size--xxl;
    }
  }
}
