@import 'styles/variables';
@import 'styles/mixins';

.flow-step {
  width: 100%;
  margin: 24px auto;
  text-align: center;

  > div:first-child {
    margin-bottom: 28px;
  }

  &__heading,
  &__subheading {
    font-weight: $font-weight--bold;
  }

  &__heading {
    font-size: $font-size--lg;
  }

  &__subheading {
    margin-top: 12px;
    font-size: $font-size--md;
  }

  &__superheading {
    font-size: $font-size--md;
    margin-bottom: 6px;
  }

  form {
    width: 50%;
    height: 60vh;
    margin: 0 auto;
    padding: 0 4px;
    overflow: auto;
    text-align: left;

    > div {
      margin-bottom: 28px;
    }

    label {
      font-size: $font-size--base;
      font-weight: $font-weight--medium;
    }

    @media #{$media-desktop} {
      width: 55%;
    }

    @media #{$media-desktop--sm} {
      width: 65%;
    }

    @media #{$media-tablet} {
      width: 80%;

      > div {
        margin-bottom: 24px;
      }
    }

    @media #{$media-desktop--short} {
      width: 65%;
    }

    @media #{$media-mobile} {
      width: 100%;
      height: 100%;
      overflow: unset;
    }
  }
}
