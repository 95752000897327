@import 'styles/variables';
@import 'styles/mixins';

@each $icon, $image, $background, $color, $padding-tb, $padding-lr, $radius,
  $fontWeight in $social-btn-list
{
  .social-btn {
    @include flex-center();

    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: $border-radius--sm;
    border: 1px solid #ccc;
    font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
      'Arial', sans-serif;
    overflow: hidden;
    font-size: $font-size--md;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &.#{$icon} {
      background-color: $background;
      color: $color;
      padding: $padding-tb $padding-lr;
      border-radius: $radius;
      font-weight: $fontWeight;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 10px;
        display: block;
        background: url($image), $background;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
}
