@import 'styles/variables';

.file-list {
  height: 250px;
  margin-bottom: 15px;

  > li:last-child {
    border-bottom: 2px solid $button-bg;
  }
}
