@import 'styles/variables';
@import 'styles/mixins';

.place-plots-form {
  @include step-with-image();

  &__action-buttons {
    @include flex(row, center, space-between);

    button {
      min-width: unset;
    }

    > div,
    > button {
      width: 48%;
      margin-bottom: 16px;
      font-size: $font-size--base;
    }

    > div > button {
      width: 100%;
    }
  }
}
