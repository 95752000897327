@import 'styles/variables';
@import 'styles/mixins';

.menu-button {
  @include default-button();

  height: 40px;
  width: 100%;
  padding: 0 1.2rem 0 1rem;
  font-size: $font-size--sm;

  &:hover,
  &:focus-visible {
    outline: none;
    background-color: $bg-light;
  }

  svg {
    @include size(16px !important);

    margin-right: 10px;
    color: $text-grey;
  }

  &--loading svg {
    @include size(20px !important);
  }
}
