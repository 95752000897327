@import 'styles/variables';
@import 'styles/mixins';

.step-form-section-heading,
.step-form-section-heading-single-input {
  font-weight: $font-weight--bold;
  font-size: $font-size--md;
  margin-bottom: 20px !important;

  div:has(> svg) {
    margin-left: 4px;
    margin-top: 4px;
    display: inline-flex;
  }
}

.step-form-section-heading {
  @include flex(row, left, center);
}

.step-form-section-heading-single-input {
  @include flex(row, left);
  margin-left: 295px;
}

.step-form-horizontal-divider {
  height: 2px;
  background-color: #dddddd;
}

.step-form-horizontal-pair-container {
  @include flex(row);
  margin-bottom: 20px !important;

  > div:nth-child(1) {
    margin: 0 8px 0 0;
    padding: 0px !important;
    width: 48% !important;
  }

  :global .custom-select-dropdown-other-open {
    width: unset;
  }

  > div:nth-child(2) {
    margin: 0 0 0 8px;
    padding: 0px !important;
    width: 48%;

    :global .custom-select-other-input {
      width: 55%;
    }
  }

  :global .radio-group-container {
    @include flex(row);
    padding-top: 4px;

    input,
    label {
      display: inline-block;
      margin-top: 10px;
    }

    :global .radio-group-label {
      margin-bottom: 4px !important;
      width: 100%;
    }

    > div:nth-child(2),
    div:nth-child(3) {
      margin-bottom: 10px !important;
    }

    > div:nth-child(3) {
      margin-left: 4px;
    }
  }

  :global .form-element-container {
    @include flex(column, left, center);

    :global .form-element-label-group {
      align-items: unset !important;
      margin-bottom: 4px !important;
      margin-left: 0px !important;
      width: 100%;
    }
  }
}

.step-form-static-text {
  font-size: $font-size--base;

  &__label {
    font-weight: $font-weight--medium;
    margin: 4px 0;
  }

  &__value {
    margin-top: 15px;
  }
}

.step-form-annotated-input {
  input {
    margin-bottom: 10px !important;
  }
}

.step-form-summary-field {
  @include flex(row);
  margin-bottom: 20px !important;
  font-size: $font-size--base;

  &__label {
    font-weight: $font-weight--medium;
    margin-right: 10px;
  }

  &__value {
  }
}

.step-form-explanatory-paragraphs {
  font-size: $font-size--sm;
  padding-left: 16px !important;
  margin-top: 5px !important;

  p {
    margin-bottom: 10px;
  }
}

.step-form-footer {
  font-size: $font-size--base;
  font-weight: $font-weight--medium;
}
