@import 'styles/variables';
@import 'styles/mixins';

.field-trials-table {
  &__button {
    // shrink button for over-wide field trials table
    min-width: unset !important;
    max-width: 175px !important;
    @include table-button;
  }
}
