@import 'styles/variables';
@import 'styles/mixins';

.uploaded-file {
  &,
  &__details {
    @include flex-center();
  }

  justify-content: space-between;
  margin-bottom: 8px;

  &__icon {
    position: relative;

    svg {
      height: 40px;
      width: 40px;
      color: $input-border-color;
    }

    span {
      padding: 0 3px;
      position: absolute;
      left: 0;
      bottom: 12px;
      font-size: $font-size--xs;
      line-height: $font-size--md;
      font-weight: $font-weight--bold;
      color: $white;
      background-color: $primary-color;
    }
  }

  &--placeholder &__name {
    color: $text-grey;
  }

  &__name,
  &__error {
    line-height: $font-size--md;
  }

  &__error {
    color: $error-color;
  }

  &__warning {
    color: black;
  }

  &__remove-button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $primary-color !important;

    svg {
      height: 16px;
      width: 16px;
      color: $white;
    }
  }

  @media #{$media-mobile--sm} {
    &__name,
    &__error {
      font-size: $font-size--xs;
      line-height: $font-size--xs;
    }

    &__remove-button {
      height: 18px;
      width: 18px;

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
}
