@import 'styles/variables';
@import 'styles/mixins';

.form-element {
  height: 40px;
  width: 100%;
  margin-bottom: 4px;
  padding: 0 10px;
  border: 1px solid $input-border-color;
  background-color: $white;
  border-radius: $border-radius--sm;
  font-size: $font-size--base;
  transition: all 0.3s;

  &:hover,
  &:focus {
    border-color: $brand-color;
    outline: none;
  }

  &__container--disabled & {
    &,
    &:hover,
    &:focus {
      opacity: 0.6;
      border: 1px solid $input-border-color;
    }
  }

  &__container {
    @include flex(column);

    &--row {
      @include flex(row, flex-start, center, nowrap);
    }

    > div {
      width: 100%;
      position: relative;
    }
  }

  &__label {
    @include flex-center();

    font-size: $font-size--base;

    &-group {
      margin-bottom: 8px;
      font-weight: $font-weight--medium;

      > span {
        margin-top: 4px;
        font-size: $font-size--sm;
      }
    }

    svg {
      @include size(16px);

      margin: 4px 0 0 4px;
    }
  }

  &__container--disabled &__label-group {
    color: $text-grey;
  }

  &__container--row &__label {
    margin-bottom: 0;
  }

  &__container--row &__label-group {
    @include flex(column, flex-end);

    width: 70%;
    margin: 4px 16px 0 0;
  }

  &--invalid {
    border-color: $error-color;
  }

  @media #{$media-mobile--sm} {
    &__container--row {
      @include flex(column);
    }

    &__container--row &__label-group {
      @include flex(row, flex-end);

      width: 100%;
      margin: 0 0 8px 0;

      > span {
        font-size: $font-size--base;
        margin: 0 0 0 4px;
      }
    }
  }
}

.form-element-footer {
  font-size: $font-size--sm;
  font-weight: $font-weight--normal;

  div > p {
    padding-bottom: 8px;
  }
}

.input {
  &-group {
    @include position();
    padding-right: 10px;
  }

  &--has-left {
    padding-left: 48px;
  }

  &--has-right {
    padding-right: 48px;
  }

  &__left-element,
  &__right-element {
    @include position(absolute, 12px);

    font-size: $font-size--md;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__left-element {
    left: 16px;
  }

  &__right-element {
    right: 16px;
  }
}
