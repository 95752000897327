@import 'styles/variables';
@import 'styles/mixins';

@mixin image-styles {
  margin-right: 40px;
  svg {
    @include position(absolute, 50%, unset, unset, 50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  img {
    @include size(250px);
  }
}

@mixin mq-image-styles {
  margin: 0 0 40px 0;

  img {
    @include size(300px);
  }
}

.analysis-landing-link {
  @include flex(row, center, right);
  color: $brand-color;
  padding-top: 15px;
}

.analysis-landing-table {
  @include flex(column);

  margin: 40px auto auto auto;

  &--no-data {
    @include flex(row, center, center);

    margin-top: 56px;
  }

  &__create-trial {
    width: 250px;
  }

  &__tooltip {
    @include flex(row, center, center);

    div {
      margin: 2px 0 0 6px !important;
    }
  }

  &__button {
    min-width: 200px;
    @include table-button;
  }

  &__fields {
    @include position();
    @include flex(row, unset, unset, nowrap);

    width: 100%;

    &__header {
      //@include position(absolute, -59px, unset, unset, 290px);
      margin-bottom: 15px;
      font-size: $font-size--xl;
      font-weight: $font-weight--bold;

      &__right-header {
        float: right;
        font-size: 16px;
      }
    }

    &__image {
      @include image-styles;
    }

    &__image--non-square {
      @include image-styles;

      img {
        object-fit: contain !important;
      }
    }
    &__image--trial-design {
      @include image-styles;

      img {
        width: 206px !important;
        height: 206px !important;
        margin: 0 22px 0 22px !important;
      }
    }

    &__container {
      width: 100%;
    }
  }

  @media #{$media-tablet} {
    align-items: center;

    &__create-trial {
      width: 300px;
    }

    &__fields {
      @include flex(column, center, unset, nowrap);

      &__header {
        @include position();

        margin-bottom: 16px;
        margin-right: auto;
      }

      &__image {
        @include mq-image-styles;
      }
      &__image--non-square {
        @include mq-image-styles;

        img {
          object-fit: contain !important;
        }
      }
    }
  }

  @media #{$media-mobile--sm} {
    &__create-trial,
    &__fields__image img {
      width: 100%;
    }
  }
}
