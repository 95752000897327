@import 'styles/variables';
@import 'styles/mixins';

.drawer-nav {
  padding: 48px;
  overflow: auto;
  z-index: 9999;

  &__close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  &__hamburger {
    display: none;
    visibility: hidden;
  }

  &__logo-container {
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 24px;
  }

  &__links,
  &__footer {
    @include flex(column, center);
    margin-top: 200px;
  }

  &__try-farmtest {
    background-color: hsla(168.2, 62.89%, 19.02%, 1);
    color: $white;
    font-size: 1rem;
    line-height: 1.6em;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.05em;
    padding: 2em 1.5em 2em 1.5em;
    border-radius: 2px;
    margin: 50px;
    height: 51px;
    width: 133px;
  }

  @media #{$media-mobile} {
    &__divider {
      margin: 8px 0 40px 0;
    }
    &__hambuger {
      display: block;
      visibility: visible;
    }
  }

  @media #{$media-mobile--sm} {
    width: 100vw !important;
    &__hamburger {
      display: block;
      visibility: visible;
    }
  }
}
