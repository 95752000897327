@import 'styles/variables';
@import 'styles/mixins';

.radio-input {
  @include flex-center();
  margin-bottom: 24px !important;

  input {
    //border-color: $primary-color;
    accent-color: $brand-color;
    height: 18px;
    width: 18px;
  }

  label {
    @include flex-center();

    margin-left: 6px;
    margin-top: 4px;

    font-size: $font-size--base;

    div {
      margin-left: 4px;
      margin-top: 4px;
    }
  }
}

.radio-input-single-input {
  @extend .radio-input;
  margin-left: 295px !important;
}

.radio-group-header {
  margin-bottom: 20px !important;
  h2 {
    font-size: $font-size--md;
    font-weight: $font-weight--medium;
  }
}

.radio-group-footer {
  font-size: $font-size--sm;
  font-weight: $font-weight--normal;
}
