@import 'styles/variables';
@import 'styles/mixins';

.table {
  width: 100%;
  border-collapse: collapse;

  &__container {
    max-height: 500px;
    width: 100%;
    overflow: auto;
  }

  td,
  th {
    padding: 16px 12px;
    font-size: $font-size--base;
    text-align: center;
    white-space: nowrap;
  }

  &__head {
    @include position(sticky, 0);

    &__col {
      display: inline-block;
      margin-left: 4px;
      transition: 0.3s;

      &--sorted {
        transform: rotate(180deg);
      }
    }

    th {
      font-weight: $font-weight--bold;
      background-color: $table-header-bg;
      box-shadow: $button-shadow--brand;

      > div {
        @include flex-center();
      }

      &:first-child {
        border-top-left-radius: $border-radius--sm;
        border-bottom-left-radius: $border-radius--sm;
      }

      &:last-child {
        border-top-right-radius: $border-radius--sm;
        border-bottom-right-radius: $border-radius--sm;
      }
    }
  }

  &__body {
    &__row {
      cursor: pointer;

      &:hover {
        background-color: $table-row-bg--light;
      }

      &--active {
        &,
        &:hover {
          background-color: $table-row-bg;
        }
      }
    }

    a {
      @include table-link();
    }

    svg {
      @include size(16px);
    }
  }

  @media #{$media-tablet} {
    &__container {
      width: 100%;
    }
  }

  @media #{$media-mobile--sm} {
    td,
    th {
      padding: 14px 10px;
      font-size: $font-size--sm;
    }
  }
}
