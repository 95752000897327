@import 'styles/variables';
@import 'styles/mixins';

.button {
  @include default-button();

  width: fit-content;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  background-color: $button-bg;

  &:hover,
  &:focus {
    background-color: $button-bg;
    box-shadow: $button-shadow;
    text-decoration: none;
  }

  &--brand {
    height: 40px;
    min-width: 200px;
    border-radius: $border-radius--sm;
    color: $white;
    font-size: $font-size--md;
    font-weight: $font-weight--medium;
    background-color: $brand-color;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: $brand-color--secondary !important;
      box-shadow: $button-shadow--brand;
      outline: none;
    }

    &:active {
      background-color: $brand-color--tertiary;
    }
  }

  &--ghost,
  &--transparent,
  &--icon {
    background-color: transparent !important;
  }

  &--transparent,
  &--icon {
    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &--icon {
    height: fit-content;
    padding: 0;
  }

  &__left-icon,
  &__right-icon {
    margin-top: 2px;

    svg {
      height: 14px;
      width: 14px;
      color: inherit;
    }
  }

  &__left-icon {
    margin-right: 8px;
  }

  &__right-icon {
    margin-left: 8px;
  }

  &--loading {
    width: 100px;
    padding: 0;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      box-shadow: none;
    }
  }

  &--disabled.button--brand {
    &,
    &:hover,
    &:focus {
      background-color: $brand-color;
    }
  }

  &--default {
    width: 150px;
  }
}
