@import 'styles/variables';
@import 'styles/mixins';

.analysis-landing-content-error {
  @include flex(column, center, center);
  margin-top: 56px;

  b {
    display: inline;
  }
}
