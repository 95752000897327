@import 'styles/variables';

@mixin flex(
  $direction: row,
  $align: flex-start,
  $justify: flex-start,
  $wrap: wrap
) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin default-button {
  @include flex-center();

  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

@mixin table-button {
  // Overrides for button inside table
  background-color: $brand-color !important;
  height: 40px !important;
  color: #ffffff !important;
}

@mixin size($size) {
  height: $size;
  width: $size;
}

@mixin position(
  $position: relative,
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin auth-form {
  &,
  > div {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }

  @media #{$media-mobile--sm} {
    > div {
      margin-bottom: 16px;
    }
  }
}

@mixin default-modal {
  background-color: $white;
  border-radius: $border-radius--md;
}

@mixin step-with-image {
  @include flex(row, flex-start, space-between, nowrap);

  width: 65% !important;

  > div:first-child {
    width: calc(100% - 450px);

    > div {
      margin-bottom: 24px;
    }
  }

  @media #{$media-desktop} {
    width: 80% !important;
  }

  @media #{$media-desktop--sm} {
    width: 95% !important;

    > div:first-child {
      width: calc(100% - 400px);
    }
  }

  @media #{$media-tablet} {
    width: 100% !important;

    > div:first-child {
      width: calc(100% - 324px);

      > div {
        margin-bottom: 16px;
      }
    }
  }

  @media #{$media-mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > div:first-child {
      width: 100%;
    }
  }
}

@mixin table-link {
  @include default-button();

  height: unset;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  font-size: $font-size--base;
  color: $brand-color--secondary;

  &:hover {
    color: $brand-color--tertiary;
    text-decoration: underline;
  }
}
