@import 'styles/variables';
@import 'styles/mixins';

.form-only-step-submit-button-container {
  margin-top: 40px !important;
  display: block;
}

.form-only-step-submit-error {
  color: $error-color;
  font-size: $font-size--sm;
  font-weight: $font-weight--normal;
}
