@import 'styles/variables';

.menu {
  display: none;
  position: relative;

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
  }

  &--visible {
    display: block;
  }

  &--visible &__content {
    animation: dropdown 0.25s;
  }

  &__content {
    position: absolute;
    min-width: 200px;
    margin-top: 4px;
    z-index: 2;
    background-color: #fff;
    border-radius: $border-radius--sm;
    box-shadow: $bg-dark--secondary 0px 0px 15px,
      $bg-dark--secondary 0px 0px 3px 1px;

    &--mirrored {
      right: 0;
    }

    @media #{$media-mobile--sm} {
      right: 16px;
    }
  }

  &__button-container {
    &--active {
      button {
        background-color: $bg-light--secondary;
      }
    }
  }
}

/** Dropdown animation **/
@-webkit-keyframes dropdown {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes dropdown {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
