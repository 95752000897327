@import 'styles/variables';
@import 'styles/mixins';

.form-error-message {
  color: $error-color;
  font-size: $font-size--xs;
  line-height: $font-size--sm;
  font-weight: $font-weight--normal;

  &--default {
    @include position(static);
  }

  &--auth {
    margin-bottom: 8px;
    font-size: $font-size--sm;
    line-height: $font-size--md;
  }

  &--success {
    color: green;
  }

  &--standalone {
    margin: 80px auto;
    color: $text-grey;
    font-size: $font-size--xl;
    line-height: 130%;
    font-weight: $font-weight--medium;
    text-align: center;

    a {
      color: $brand-color--secondary;
      text-decoration: underline;
    }
  }
}
