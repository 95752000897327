@import 'styles/variables';
@import 'styles/mixins';

.purchase-modal {
  @include default-modal();

  &__header {
    @include flex-center();

    justify-content: space-between;
    border-top-left-radius: $border-radius--md;
    border-top-right-radius: $border-radius--md;
    border-bottom: 2px solid $input-border-color;
    background-color: $button-bg;

    h1 {
      font-weight: $font-weight--bold;
      font-size: $font-size--lg;
    }

    svg {
      height: 24px;
      width: 24px;
      color: $input-border-color;
    }
  }

  &__header,
  &__content,
  &__footer {
    padding: 10px;
  }

  &__description {
    &__header {
      font-size: $font-size--md;
      font-weight: $font-weight--bold;
    }

    &__file-types {
      line-height: $font-size--md;
    }
  }

  &__purchase-options {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    &__option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      margin: 1px;
      border: 1px solid $input-border-color;
      border-radius: $border-radius--md;
      width: 100%;

      &__list-items {
        margin-top: 8px;
      }

      &__list-item {
        margin-top: 8px;
      }

      &__button {
        margin-top: 16px;
        padding: auto;
        max-width: fit-content;
        min-width: 50px;
      }
    }
  }

  &__choice-error {
    margin-top: 16px;
    font-size: $font-size--base;
    color: $error-color;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $button-bg;

    button {
      margin-left: 16px;
      padding: 0 16px;
    }

    &--disabled {
      opacity: 0.6;
    }
  }
}
