@import 'styles/variables';
@import 'styles/mixins';

.abline-form {
  @include step-with-image();

  h2 {
    font-size: $font-size--md;
    font-weight: $font-weight--medium;
    margin-bottom: 24px;
  }

  &__method-select {
    font-size: $font-size--base;
    font-weight: $font-weight--medium;

    > div {
      margin-left: 16px;
    }

    > div,
    > p {
      margin-bottom: 24px;
    }
  }

  > div {
    align-items: flex-start;
  }
}
