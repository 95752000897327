@import 'styles/variables';
@import 'styles/mixins';

.step-pagination {
  @include position(absolute, unset, 5%, 10vh, 5%);
  @include flex(row, center, space-between);

  > button,
  > span {
    width: 75px;
  }

  > button {
    height: 35px;
    font-size: $font-size--base;
    font-weight: $font-weight--medium;
    border-radius: $border-radius--md;

    &:hover {
      background-color: $brand-color !important;
    }
  }

  &__step-button {
    &-list {
      @include flex-center();
    }

    @include default-button();

    height: 20px;
    width: 60px;
    margin-right: 8px;
    background-color: $white;
    border: 2px solid $primary-color;
    border-radius: $border-radius--sm;
    box-shadow: none;

    &--current,
    &--active {
      border-color: $brand-color;
    }

    &--active {
      background-color: $brand-color;
    }
  }
  @media #{$media-desktop--short} {
    //@include position(static);
    @include position(absolute, unset, 5%, 0vh, 5%);
  }

  @media #{$media-mobile} {
    @include position(static);
    &__step-button {
      height: 15px;
      width: 40px;
      margin-right: 5px;
    }
  }
}
