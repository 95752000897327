@import 'styles/variables';
@import 'styles/mixins';

.header {
  &--outer {
    @include position(sticky, 0);

    max-width: auto;
    margin: 0 auto;
    padding: 0 60px;
    background-color: $white;
    z-index: 1;
    border-bottom: 5px solid $brand-color;

    &--logged-in {
      padding: 0 40px;
    }
  }

  @include position(relative);
  @include flex(row, center, left);

  height: 65px;

  &__action-buttons,
  &__auth-links {
    @include position(absolute);

    > a:first-child {
      padding: 0;
      margin-right: 16px;

      &:focus {
        outline: none;
      }
    }
  }

  &__action-buttons {
    @include flex-center();

    right: 0;

    > button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  &__logo {
    max-height: 21px;
    width: auto;
    max-width: 100%;
  }

  &__auth-links {
    @include flex-center();

    right: 0;

    > a {
      padding: 0 16px;
      margin: 0px;
      font-size: 0.85em;
      font-weight: 400;
      letter-spacing: 0em;
      font-family: $nav-font-family;
      min-width: fit-content;
    }
  }

  @media #{$media-desktop--sm} {
    height: 100px;

    &--outer {
      padding: 0 48px;

      &--logged-in {
        padding: 0 32px;
      }
    }
  }

  @media #{$media-tablet} {
    &--outer {
      padding: 0 24px;
    }

    &__logo {
      margin-left: -36px;
      max-height: 16px;
    }

    &__auth-links > a {
      font-size: $font-size--base;
    }
  }

  @media #{$media-mobile} {
    height: 75px;

    &__logo {
      margin-left: -16px;
      max-height: 16px;
    }

    &__auth-links > a {
      &:first-child {
        display: none;
      }

      height: 35px;
      font-size: $font-size--base;
    }
  }

  @media #{$media-mobile--sm} {
    &__logo {
      margin: 0;
      max-height: 16px;
    }

    &__auth-links {
      display: none;
    }
  }
}
