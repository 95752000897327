@import 'styles/variables';
@import 'styles/mixins';

.image-preview {
  @include position();

  border-radius: $border-radius--sm;

  &,
  img {
    @include size(400px);
  }

  svg {
    @include position(absolute, 50%, unset, unset, 50%);

    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &--no-image {
    background-color: $bg-dark--tertiary;
  }

  &--loading {
    img {
      opacity: 0.2;
    }
  }

  &--error {
    color: red;
  }

  &--offline {
    color: orange;
    font-weight: bold;
  }

  &--small {
    width: 250px;
    height: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  @media #{$media-desktop--sm} {
    &,
    img {
      @include size(350px);
    }
  }

  @media #{$media-tablet} {
    &,
    img {
      @include size(300px);
    }
  }

  @media #{$media-mobile} {
    &,
    img {
      max-width: 100%;
      max-height: 100%;
    }

    &--no-image {
      width: 100%;
      height: 300px;
    }
  }
}
