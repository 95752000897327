@import 'styles/variables';
@import 'styles/mixins';

.confirm-modal {
  @include default-modal();

  padding: 24px 16px 16px 16px;
  text-align: center;

  &__heading {
    margin-bottom: 12px;
    font-size: $font-size--lg;
    font-weight: $font-weight--bold;
  }

  &__description {
    margin-bottom: 32px;
    font-size: $font-size--base;
    line-height: $font-size--lg;
  }

  &__error {
    margin-bottom: 16px;
    font-size: $font-size--base;
    color: $error-color;
  }

  &__footer {
    @include flex(row, center, flex-end);

    button {
      @include flex-center();

      height: 35px;
      width: fit-content;
      min-width: fit-content;
      margin-left: 16px;
      padding: 0 16px;
      font-size: $font-size--base;
      font-weight: $font-weight--medium;
      border-radius: $border-radius--sm;

      svg {
        @include size(20px);
      }
    }
  }
}
