@import 'styles/variables';

.drawer-navlink {
  margin-bottom: 36px;
  color: $navlink-color;
  font-family: $nav-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 0.9rem;

  &:hover,
  &--active {
    color: $navlink-color--active;
    text-decoration: none;
  }

  @media #{$media-mobile} {
    font-size: 8.5vmin;
  }

  @media #{$media-mobile--sm} {
    font-size: 8.5vmin;
  }
}
