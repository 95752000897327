@import 'styles/variables';

.auth-footer-redirect {
  margin-bottom: 16px;
  font-size: $font-size--sm;

  a {
    margin-left: 4px;
    color: $brand-color--secondary;
    transition: all 0.3s;

    &:hover {
      color: $brand-color--tertiary;
      text-decoration: none;
    }
  }
}
