@import 'styles/variables';
@import 'styles/mixins';

.file-upload {
  @include default-modal();

  &__header {
    @include flex-center();

    justify-content: space-between;
    border-top-left-radius: $border-radius--md;
    border-top-right-radius: $border-radius--md;
    border-bottom: 2px solid $input-border-color;
    background-color: $button-bg;

    h1 {
      font-weight: $font-weight--bold;
      font-size: $font-size--lg;
    }

    svg {
      height: 24px;
      width: 24px;
      color: $input-border-color;
    }
  }

  &__header,
  &__content,
  &__footer {
    padding: 10px;
  }

  &__description {
    &__header {
      font-size: $font-size--md;
      font-weight: $font-weight--bold;
    }

    &__file-types {
      line-height: $font-size--md;
    }

    &__error {
      color: $error-color;
      font-size: $font-size--sm;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $button-bg;

    button {
      margin-left: 16px;
      padding: 0 16px;
    }

    &__upload-button {
      font-weight: $font-weight--bold;
    }
  }
}
