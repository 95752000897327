@import 'styles/variables';
@import 'styles/mixins';

.modal {
  @include flex-center();

  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: $modal-overlay;
  justify-content: center;
  z-index: 999;

  > div {
    width: 30%;
    max-width: 500px;

    @media #{$media-desktop--sm} {
      width: 45%;
    }

    @media #{$media-tablet} {
      width: 55%;
    }

    @media #{$media-mobile} {
      width: 65%;
    }

    @media #{$media-mobile--sm} {
      width: 90%;
    }
  }
}
