@import 'styles/variables';
@import 'styles/mixins';

.login-form {
  &__separator {
    @include flex(row, center, space-between);

    span {
      display: inline-block;
      width: 42%;
      height: 1px;
      background-color: $input-border-color;
    }

    p {
      font-weight: $font-weight--medium;
    }
  }

  &__footer {
    @include flex(row, center, space-between);

    margin: -12px 0 36px 0;
    font-size: $font-size--sm;

    a {
      color: $brand-color--secondary;
      transition: all 0.3s;

      &:hover {
        color: $brand-color--tertiary;
        text-decoration: none;
      }
    }
  }
}
