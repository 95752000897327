@import 'styles/variables';

.terms-modal {
  padding: 8px 16px 24px 16px;
  border-radius: $border-radius--lg;
  background-color: $white;

  &__close-button {
    &-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
    }

    svg {
      height: 20px;
      width: 20px;
      color: $primary-color;
    }
  }

  &__description,
  &__checkboxes {
    margin-bottom: 16px;
  }

  &__continue-button {
    width: 100%;
    height: 40px;
  }
}
