@import 'styles/variables';
@import 'styles/mixins';

.date-step-upload-form {
  padding-left: 29%;
}

.date-step-text {
  &--error {
    color: red;
  }
}
