@import 'styles/variables';
@import 'styles/mixins';

.upload-modal-button {
  text-align: left;

  &--disabled {
    color: $text-grey;
  }

  > p:first-child {
    margin-bottom: 8px;
    font-size: $font-size--base;
    font-weight: $font-weight--medium;
  }

  button {
    height: 35px;
    min-width: fit-content;
    padding: 0 16px;
    font-size: $font-size--base;
  }

  &__demo {
    margin-bottom: 8px;
    text-transform: capitalize;
  }

  &__container {
    @include flex-center();

    margin-bottom: 4px;
  }

  &__file-info {
    margin-left: 8px;
  }
}
