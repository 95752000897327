@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap');
@import 'variables';
@import 'mixins';
@import 'reset';

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  color: $primary-color;
  font-size: $font-size--sm;
  line-height: $font-size--md;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: $button-font-family;

  &[datatype='table-link'] {
    @include table-link();
  }
}

pre {
  font-family: $pre-font-family;
  white-space: pre-wrap;
}

img {
  object-fit: cover;
}

a {
  color: $link-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
