@import 'styles/variables';
@import 'styles/mixins';

.workspace-content {
  @include flex(column);

  margin: 48px auto 80px auto;

  &--no-data {
    @include flex(row, center, center);

    margin-top: 56px;
  }

  &__create-trial {
    width: 250px;
  }

  &__fields {
    @include position();
    @include flex(row, unset, unset, nowrap);

    width: 100%;
    margin-top: 32px;

    &__header {
      @include position(absolute, -59px, unset, unset, 290px);

      font-size: $font-size--xl;
      font-weight: $font-weight--bold;
    }

    &__image {
      margin-right: 40px;

      img {
        @include size(250px);
      }
    }
  }

  @media #{$media-tablet} {
    align-items: center;

    &__create-trial {
      width: 300px;
    }

    &__fields {
      @include flex(column, center, unset, nowrap);

      &__header {
        @include position();

        margin-bottom: 16px;
        margin-right: auto;
      }

      &__image {
        margin: 0 0 40px 0;

        img {
          @include size(300px);
        }
      }
    }
  }

  @media #{$media-mobile--sm} {
    &__create-trial,
    &__fields__image img {
      width: 100%;
    }
  }
}
