@import 'styles/variables';
@import 'styles/mixins';

.trial-type-form {
  &__product {
    @include flex(row, center, space-between);

    > div {
      width: 48%;
    }
  }

  @media #{$media-mobile} {
    &__product {
      flex-direction: column;

      > div {
        width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.trial-type-form__select {
  padding-left: 0px !important;
}
