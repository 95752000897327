@import 'styles/variables';
@import 'styles/mixins';

.open-select {
  @include flex(row, flex-end, space-between);

  > div {
    width: 100%;
  }

  &--other-active {
    // in the case of "other active", we want to align the two inputs to
    // the same left point as other single inputs, and split them so that
    // other is visible but the most space is given to the textbox.
    padding-left: 220px;

    > div:nth-child(1) {
      width: 33%;
    }
    > div:nth-child(2) {
      width: 63%;
    }
  }

  @media #{$media-mobile} {
    flex-direction: column;

    &--other-active > div {
      width: 100%;

      &:last-child > div:first-child {
        margin-top: 20px;
      }
    }
  }
}
