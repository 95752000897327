@import 'styles/mixins';
@import 'styles/variables';

.file-chooser {
  @include flex-center();

  justify-content: center;
  position: relative;
  margin: 12px 0;
  padding: 10px 0;
  border: 3px dotted $input-border-color;

  &__button {
    font-weight: $font-weight--bold;
    border-radius: $border-radius--sm;
    padding: 0 16px;
  }

  &__input {
    height: 30px;
    width: 200px;
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__input:disabled {
    cursor: not-allowed;
  }

  &__input:hover + &__button,
  &__input:focus + &__button {
    box-shadow: $button-shadow;
  }

  &__input:focus + &__button {
    outline: 1px solid $form-element-outline;
  }

  &__input:disabled:hover + &__button,
  &__input:disabled:focus + &__button {
    box-shadow: none;
  }

  &__input:disabled:focus + &__button {
    outline: none;
  }
}
