@import 'styles/variables';
@import 'styles/mixins';

.tooltip {
  @include position();

  cursor: pointer;

  &__label {
    @include position(absolute);

    max-width: 300px;
    height: fit-content;
    padding: 10px 12px;
    font-size: $font-size--sm;
    line-height: $font-size--md;
    border-radius: $border-radius--md;
    text-align: center;
    color: $white;
    background-color: $tooltip-bg;
    transition: opacity 0.3s;
    z-index: 1;
    cursor: default;

    &::after {
      @include position(absolute, 100%, unset, unset, 50%);

      content: ' ';
      margin-left: -8px;
      border: 8px solid;
      border-color: $tooltip-bg transparent transparent transparent;
    }
  }
}
