@import '../../../styles/variables';
@import '../../../styles/mixins';

.form-step-and-image-container {
  @include step-with-image();
  margin: 0 auto;
  padding: 0 4px;
  overflow: auto;
  text-align: left;

  > div {
    margin-bottom: 28px;
  }

  label {
    font-size: $font-size--base;
    font-weight: $font-weight--medium;
  }

  @media #{$media-tablet} {
    > div {
      margin-bottom: 24px;
    }
  }
}
