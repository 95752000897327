@import 'styles/variables';
@import 'styles/mixins';

.footer {
  margin: 10px 30px 30px 0;
  width: fit-content;

  &__help-button {
    margin-bottom: 8px;
  }

  &__links {
    @include flex(row, center, space-between);

    width: 100%;
  }
}
